<template>
  <div
    v-if="data?.socialIcons?.[0]"
    class="social-icons"
  >
    <div
      v-if="data?.socialIcons[0]?.Title"
      class="social-icons__title visually-hidden"
    >
      {{ data?.socialIcons[0].Title }}
    </div>

    <ul class="social-icons__list">
      <li
        v-for="(group, index) in data.socialIcons[0].Icons"
        :key="index"
      >
        <component
          :is="getElement(group.Link)"
          class="social-icons__link"
          :class="`${group.Name.toLowerCase().replace(/\s/g, '')}`"
          :to="group.Link"
        >
          <component
            :is="loadIcon(group.Icon)"
            v-if="group.Icon"
            class="icon"
            :class="`icon--${useDash(group.Icon)}`"
          />
          <span class="visually-hidden">{{ group.Name }}</span>
        </component>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import layoutQuery from '~layers/app/graphql/layout.gql'

import {
  AppLink,
} from '#components'

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

function getElement(to: any) {
  return to ? AppLink : 'span'
}

const { loadIcon } = useStrapiIcons()
</script>

<!-- <style src="~layers/app/components/App/Socials.css" /> -->
<style>
@import url("~layers/app/components/App/Socials.css");
</style>
